@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url('./SF-Pro-Display-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url('./SF-Pro-Display-light.woff2') format('woff2');
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url('./SF-Pro-Display-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: bold;
}